<template>
  <div class="select-province-wrapp">
    <base-select
      v-show="options.length"
      :name="name"
      v-model="_value"
      :selectClass="selectClass"
      :options="options"
      v-validate="'required'"
      :validations="validations"
      :clearable="false"
      :placeholder="$t('address.select-province')"
      >
    </base-select>
    <template v-if="!options.length && !disableManualInput">
      <b-form-input
        type="text"
        v-model="_value"
        :autocomplete="'off'"
        class="manual-province-input"
        :name="`${this.name}-input`"
        :placeholder="$t('address.Province')"
        >
      </b-form-input>
    </template>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import uniqBy from 'lodash/uniqBy'
import find from 'lodash/find'
import BaseSelect from 'theme/components/Form/BaseSelect'
import BaseInput from 'theme/components/Form/BaseInput'

export default {
  props: {
    name: {
      type: String,
      required: false,
      default: ''
    },
    value: {
      type: String,
      required: false,
      default: ''
    },
    countryId: {
      type: String,
      default: ''
    },
    validations: {
      type: Array,
      required: false,
      default: () => []
    },
    selectClass: {
      type: String,
      required: false
    },
    // reduce: {
    //   type: Function,
    //   default: () => {}
    // },
    countries: {
      type: Array,
      required: false
    },
    disableManualInput: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  mounted () {
    if (process.client) {
      if (!this.countries || this.countries.length !== 0) {
        return this.$store.dispatch('checkout/loadCountries')
      }
    }
  },
  computed: {
    available_regions () {
      if (this.$store.state.checkout.countries.length > 0) {
        const countryFound = find(this.$store.state.checkout.countries, { 'id': this.countryId })
        return countryFound ? countryFound.available_regions : []
      }
      return []
    },
    _value: {
      get () {
        return this.value
      },
      set: debounce(function (newValue) {
        this.$emit('input', newValue.hasOwnProperty('code') ? newValue.code : newValue)
      }, 500)
    },
    options () {
      // remove unique by if regoin not have duplicated
      if (this.available_regions) {
        const options = uniqBy(this.available_regions, 'code').map(item => ({ code: item.code, label: item.name, id: item.id }))
        this.$emit('provinceOptions', options)
        return options
      } else {
        this.$emit('provinceOptions', [])
        return []
      }
    }
  },
  components: {
    BaseInput,
    BaseSelect
  }
}
</script>
