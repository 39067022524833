import { render, staticRenderFns } from "./MyAddressesEdit.vue?vue&type=template&id=0a868213&"
import script from "./MyAddressesEdit.vue?vue&type=script&lang=js&"
export * from "./MyAddressesEdit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "MyAddressesEdit.vue"
export default component.exports